import React, { useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { LanguageProvider } from "../../../core/language-provider";
import cn from "../../../utils/classNames";
import { PagePath } from "../../../utils/routes";
import { userStore } from "../../../store/user";
import useComponentVisible from "../../../hooks/useComponentVisible";
import BarLightSvg from "../../../assets/icons/bar_light";
import BarBoldSvg from "../../../assets/icons/bar_bold";
import HomeLightSvg from "../../../assets/icons/home_light";
import HomeBoldSvg from "../../../assets/icons/home_bold";
import ProductsLightSvg from "../../../assets/icons/products_light";
import ProductsBoldSvg from "../../../assets/icons/products_bold";
import MapLightSvg from "../../../assets/icons/map_light";
import MapBoldSvg from "../../../assets/icons/map_bold";
import ProfileSvg from "../../../assets/icons/profile";
import { MdMenu } from "react-icons/md";
import LogoBlack from "../../../assets/icons/logoblack";
import MenuLightSvg from "../../../assets/icons/menu_light";
import MenuBoldSvg from "../../../assets/icons/menu_bold";
import QrCodeLightSvg from "../../../assets/icons/qrcode_light";
import QrCodeBoldSvg from "../../../assets/icons/qrcode_bold";
import Button from "../../BodyElements/ButtonBlock/Button";


export default function Header(props) {
  const showNavLinks = props.showNavLinks ?? true;
  const showBarLinks = props.showBarLinks ?? true;

  const userInfo = userStore((state) => state.user);
  const location = useLocation();
  const {
    ref: navBarRef,
    isComponentVisible: isNavBarVisible,
    setIsComponentVisible: setNavBarVisible,
  } = useComponentVisible(false);

  // close sidebar on route change
  useEffect(() => {
    if (isNavBarVisible) {
      setNavBarVisible(false);
    }
  }, [location]);

  const userLinks = [
    {
      label: LanguageProvider.get("header.home"),
      icon: HomeLightSvg,
      boldIcon: HomeBoldSvg,
      to: PagePath.Home,
    },
    {
      label: LanguageProvider.get("header.bars"),
      icon: BarLightSvg,
      boldIcon: BarBoldSvg,
      to: PagePath.ExploreBars,
    },
    /* {
      label: LanguageProvider.get("header.products"),
      icon: ProductsLightSvg,
      boldIcon: ProductsBoldSvg,
      to: PagePath.ExploreProducts,
    }, */
  ];

  const adminLinks = [
    {
      label: LanguageProvider.get("admin.bar"),
      icon: BarLightSvg,
      boldIcon: BarBoldSvg,
      to: PagePath.AdminBar,
    },
    {
      label: LanguageProvider.get("admin.position"),
      icon: MapLightSvg,
      boldIcon: MapBoldSvg,
      to: PagePath.AdminPosition,
    },
    {
      label: LanguageProvider.get("admin.menu"),
      icon: MenuLightSvg,
      boldIcon: MenuBoldSvg,
      to: PagePath.AdminMenu,
    },
    {
      label: LanguageProvider.get("admin.qrcode"),
      icon: QrCodeLightSvg,
      boldIcon: QrCodeBoldSvg,
      to: PagePath.AdminQrcode,
    },
  ];

  const linksToRender =
    showBarLinks && userInfo?.idBar ? adminLinks : userLinks;

  const buildMobileNavBar = () => {
    return (
      <div className="absolute left-0 right-0 top-[100%] flex flex-col gap-8 bg-white p-6 pt-3">
        {linksToRender.map((link) => (
          <NavLink
            key={`link-${link.to}`}
            className={(state) => cn("flex w-full items-center")}
            to={link.to}
          >
            {(state) => (
              <>
                {state.isActive ? (
                  <link.boldIcon className="mr-4" />
                ) : (
                  <link.icon className="mr-4" />
                )}
                <span className="text--h4">{link.label}</span>
              </>
            )}
          </NavLink>
        ))}
      </div>
    );
  };

  return (
    <nav
      className="sticky left-0 top-0 z-10 flex h-24 w-full items-center bg-white px-8 py-4 pb-4 sm:h-32 border-b-[1px] border-slate-200"
      ref={navBarRef}
    >
      <div className="mr-auto md:hidden">
        <button
          onClick={() => setNavBarVisible((prev) => !prev)}
          className="p-2"
        >
          <MdMenu className="h-8 w-8" />
        </button>
      </div>
      <Link to="/" className="mx-auto flex-shrink-0">
        <LogoBlack />
      </Link>
      <div className="ml-auto flex md:ml-0 md:w-full">
        <div className="mx-auto hidden flex-row items-center gap-x-24 gap-y-0 text-center md:flex">
          {showNavLinks &&
            linksToRender.map((link) => (
              <NavLink
                key={`link-${link.to}`}
                className={(state) => cn("flex w-max flex-col items-center")}
                to={link.to}
              >
                {(state) => (
                  <>
                    {state.isActive ? (
                      <link.boldIcon className="mb-2" />
                    ) : (
                      <link.icon className="mb-2" />
                    )}
                    <span className="text--h4">{link.label}</span>
                  </>
                )}
              </NavLink>
            ))}
        </div>
        {/* <div className="flex flex-row items-center px-4 py-2 md:border border-solid rounded-lg">
          {!userInfo?.id && (
            <Button>
              <Link
                to={PagePath.AdminLogin}
                className="text--h3 hidden font-bold uppercase sm:block"
              >
                {LanguageProvider.get("header.login_bar")}
              </Link>
            </Button>

          )}
          <Link
            to={userInfo ? PagePath.UserProfile : PagePath.UserLogin}
            className="flex w-full items-center md:p-2"
          >
            <ProfileSvg />
          </Link>

        </div> */}

        <div className="flex flex-row gap-4">
          <div className="flex flex-row items-center px-4 py-2 md:border border-solid rounded-lg">
            <Link
              to={!userInfo ? PagePath.AdminLogin : PagePath.AdminBar}
              className="text--h3 hidden font-bold uppercase sm:block"
            >
              {LanguageProvider.get("header.login_bar")}
            </Link>
          </div>
          <Link
            to={userInfo ? PagePath.UserProfile : PagePath.UserLogin}
            className="flex items-center" style={{display: 'none'}}
          >
            <ProfileSvg />
          </Link>
        </div>

      </div>
      {isNavBarVisible && buildMobileNavBar()}
    </nav>
  );
}
